/*=============================================
=            Footer            =
=============================================*/

.footer-nav-wrapper {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 3.3rem;
  padding: 0 40px;

  border-radius: 60px;
  background-color: $white;
  box-shadow: 0 2px 10px rgba($black, 0.16);

  &--styleTwo {
    border-radius: 0;
  }
  /*   .footer-nav-single--cartActive {
    svg {
      * {
        stroke: $theme-color--default;
      }
    }
  } */
}

.footer-nav-single {
  position: relative;

  display: block;

  width: 3rem;
  height: 100%;

  text-align: center;

  border-radius: 50%;

  @media #{$extra-extra-small-mobile} {
    width: 50px;
    height: 45px;
  }

  span {
    font-size: 12px;

    color: $grey--four;
  }
  svg {
    display: block;

    margin: 0 auto;
    margin-bottom: 5px;
    @media #{$extra-extra-small-mobile} {
      width: 20px;
      height: 20px;
    }
  }

  .menu-wrapper {
    line-height: 1;

    position: absolute;
    top: 55%;
    left: 50%;

    transform: translate(-50%, -50%);

    .menu-cart--badge {
      position: absolute;
      color: #fff !important;
      bottom: 23px;
      left: 18px;
      font-size: 0.6rem;
      background-color: $theme-color--default;
      color: #333;
    }
  }

  /*   &:before {
    position: absolute;
    z-index: -1;
    top: -5px;
    left: -5px;

    visibility: hidden;

    width: calc(100% + 10px);
    height: calc(100% + 10px);

    content: "";

    opacity: 0;
    border-radius: 50%;
    background-color: $white;
  } */

  &:hover,
  &.active {
    background-color: $blue;

    span {
      color: $white;
    }
    svg {
      * {
        stroke: $white;
      }
    }

    &:before {
      visibility: visible;

      opacity: 1;
    }
  }

  &--styleTwoHome {
    svg {
      * {
        stroke: $grey--twentyOne;
      }
    }
    /*     &:before {
      position: absolute;
      z-index: -1;
      top: -2px;
      left: -10px;

      visibility: hidden;

      width: calc(100% + 20px);
      height: calc(80% + 10px);

      content: "";

      opacity: 0;
      border: 6px solid rgba($color: $grey--four, $alpha: 0);
      border-radius: 0%;
      background-color: $white;
      box-shadow: 0 0 5px rgba($black, 0.16);
    } */

    &:hover,
    &.active {
      background-color: transparent;
      svg {
        * {
          stroke: $theme-color--default;
        }
      }

      &:before {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &--styleTwo {
    svg {
      width: 26px;
      height: 26px;
      * {
        stroke: $grey--twentyOne;
      }
    }

    /* 
    &:before {
      position: absolute;
      z-index: -1;
      top: -2px;
      left: -10px;

      visibility: hidden;

      width: calc(100% + 20px);
      height: calc(80% + 10px);

      content: "";

      opacity: 0;
      border: 6px solid rgba($color: $theme-color--default, $alpha: 0);
      border-radius: 0%;
      background-color: $white;
      box-shadow: 0 0 5px rgba($theme-color--default, 0.26);
    }
 */
    &:hover,
    &.active {
      background-color: transparent;
      svg {
        * {
          stroke: $grey--twentyOne;
        }
      }

      &:before {
        visibility: visible;

        opacity: 1;
      }
    }
    &.footer-nav-selected {
      svg {
        * {
          stroke: $theme-color--default;
        }
      }
    }
  }
}
.notification-alert-wrapper {
  //display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.notification-alert-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(51, 51, 51, 0.4);
}
.notification-alert-container {
  position: absolute;
  bottom: 3.5rem;
  border-left: 5px solid $theme-color--default;
  left: 1rem;
  right: 1rem;
  border-radius: 4px;
  background-color: white;
  box-shadow: 3px 3px 10px #333333aa;
}
.notification-alert-title {
  margin: 0.9rem 0rem 0.7rem 1rem;
}
.notification-alert-title img {
  height: 1.5rem;
}
.notification-alert-message {
  text-align: center;
  font-size: 15px;
  line-height: 1.5;
  margin: 1rem 2rem;
}
.notification-alert-cta-container {
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0.5rem 1rem 1rem 0;
}
.notification-alert-cta-reject {
  color: $theme-color--default;
  border: none;
  background: transparent;
  font-weight: bold;
  margin-right: 0.5rem;
}
.notification-alert-cta-accept {
  color: white;
  border: 2px solid $theme-color--default;
  background: $theme-color--default;
  border-radius: 5px;
  padding: 0 0.8rem;
  font-weight: bold;
}
.modal-open-notification {
  overflow: hidden;
}

/*=====  Desktop ======*/

@media(min-width:800px){ 
  .footer-nav-wrapper{
  display: none;
}
.menu-wrapper {
    line-height: 1;

    position: absolute;
    top: 55%;
    left: 50%;


.menu-cart--badge {
      position: absolute;
      color: #fff !important;
      bottom: 40px !important;
      left: 24px !important;
      font-size: 0.6rem;
      // background-color: $theme-color--default;
      background-color:red !important;
      color: #333;
    }}
}


/*=====  End of Footer ======*/

