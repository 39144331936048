/*=============================================
=            checkout            =
=============================================*/

.checkout-form {
  padding: 40px 20px;

  background-color: $white;
  &__single-field {
    label {
      font-size: 14px;

      margin-bottom: 20px;

      color: $black--two;
    }
    input,
    textarea {
      font-size: 18px;

      display: block;

      width: 100%;
      padding-bottom: 10px;

      color: $black--two;
      border: none;
      border-bottom: 2px solid $grey--eight;

      &:focus {
        border-bottom: 2px solid $theme-color--default;
      }
    }

    ::placeholder {
      /* Firefox */
      opacity: 1;

      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey--four;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $grey--four;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $grey--four;
    }
  }

  &__button {
    position: fixed;
    margin-bottom: 10px;
    right: 0;
    left: 0;
    width: 100%;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: rgb(247, 248, 249), $alpha: 0.9);

    .row {
      width: 100%;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    button {
      text-align: center;
      text-transform: uppercase;
      font-size: 1rem;
      color: $white;
      background-color: $theme-color--default;
    }
  }
}

.your-order-area {
  h3 {
    font-size: 20px;
    font-weight: 500;

    margin: 0 0 20px;

    color: $black;
  }
  .your-order-wrap {
    padding: 30px 15px;

    background: $grey--seven;

    .your-order-product-info {
      .your-order-top {
        ul {
          display: flex;
          justify-content: space-between;
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $black;
          }
        }
      }
      .your-order-middle {
        margin: 29px 0;
        padding: 19px 0 18px;

        border-top: 1px solid $grey--eight;
        border-bottom: 1px solid $grey--eight;
        ul {
          li {
            display: flex;
            justify-content: space-between;

            margin: 0 0 10px;
          }
        }
      }
      .your-order-bottom {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            font-size: 14px;
            font-weight: 400;

            list-style: none;
          }
          li.your-order-shipping {
            font-size: 16px;
            font-weight: 400;

            color: $black;
          }
        }
      }
      .your-order-total {
        margin: 18px 0 0px;
        padding: 29px 0 19px;

        border-top: 1px solid $grey--eight;
        border-bottom: 1px solid $grey--eight;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li.order-total {
            font-size: 18px;
            font-weight: 500;

            color: $black;
          }
          li {
            font-size: 16px;
            font-weight: 500;

            list-style: outside none none;

            color: $theme-color--default;
          }
        }
      }
    }
  }

  .place-order > a {
    font-weight: 500;
    line-height: 1;

    z-index: 9;

    display: block;

    padding: 18px 20px;

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: $white;
    border-radius: 50px;
    background-color: $theme-color--default;
    &:hover {
      background-color: #333;
    }
  }
  .dropshipping {
    label {
      margin: 1rem 1rem 1rem 0;
      font-size: 1rem;
    }
  }
}
.deposito-radio-group-title {
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.deposito-radio-group {
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
}
.deposito-radio-group input:checked {
  background-color: $theme-color--default;
  border-color: $theme-color--default;
}
#orderNotes {
  padding: 0px;
  padding-bottom: 18px;
  font-size: 1rem;
}

@media (min-width:600px) {
  .btn-desktop-checkout{
    width: 300px;
    margin-bottom: 20px;
  }
  .checkout-form{ 
  &__button {
   .col{
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 12%;
  }
   button {
      text-align: center;
      text-transform: uppercase;
      font-size: 1rem;
      color: $white;
      background-color: $theme-color--default;
    }
  }
}
}
/*=====  End of checkout  ======*/
