.order-page-title {
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span:first-child {
    font-size: 1.2rem;
    font-weight: bold;
  }
  span:last-child {
    padding: 0 5px;
    border: 1px solid #999;
    border-radius: 5px;
  }
}
.orders {
  &-container {
    margin: 5px;
    margin-bottom: 15px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
  }
  &-header {
    &-container {
      font-size: 14px;
      background: rgb(238, 238, 238);
      padding: 5px 10px;
      position: relative;
    }
    &-header {
      position: absolute;
      top: 3px;
      font-size: 12px;
      right: 3px;
    }
    &-estado-badge {
      /*       background-color: rgb(0, 124, 186);
      color: rgb(255, 255, 255); */
      font-weight: bold;
      border: 1px solid rgb(0, 124, 186);
      color: rgb(0, 124, 186);
      display: inline-flex;
      padding: 2px 5px;
      border-radius: 4px;
    }
    &-dropshipping-badge {
      /*       background-color: rgb(0, 187, 82);
      color: rgb(255, 255, 255); */
      font-weight: bold;
      border: 1px solid rgb(0, 187, 82);
      color: rgb(0, 187, 82);
      display: inline-flex;
      padding: 2px 5px;
      border-radius: 4px;
      margin-right: 3px;
    }
    &-pedido-title {
      font-weight: 700;
    }
    &-info {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 14px;
      &-quantity {
        margin-right: 10px;
      }
      &-date {
        margin-left: 10px;
      }
      &-price {
        font-size: 18px;
        line-height: 1rem;
        font-weight: bold;
      }
    }
  }
}
.orders-item {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
    padding: 10px 0;
    flex-direction: row;
    border-bottom: 1px solid #999;
    &:last-child {
      border-bottom: none;
    }
  }

  &-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-description {
      margin: 0px;
      span {
        font-weight: bold;
        color: rgb(0, 124, 186);
      }
    }
    &-price {
      text-align: start;
      font-size: 14px;
    }
  }
  &-subtotal {
    color: rgb(0, 124, 186);
    font-size: 16px;
    align-self: center;
    font-weight: bold;
  }
}
