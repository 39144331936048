.shop-filter-wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  margin-bottom: 0.3rem;
}
.shop-filter-ofertas-novedades-container {
  display: flex;
  justify-content: center;
  font-size: 15px;
}
.custom-dropdown {
  position: relative;
}
.custom-dropdown:first-child {
  display: flex;
  align-items: center;
  height: 100%;
}

.custom-dropdown-show-title {
  font-size: 14px;
  font-weight: 400;
}
.custom-ofertas-novedades-button {
  margin-left: 3px;
  font-size: 17px;
  color: #222;
  border: 1px solid #999;
  padding: 3px 6px;
  font-weight: 100;
  border-radius: 3px;
  cursor: pointer;
  max-width: 6.2rem;
  margin-right: 5px;
}

.custom-ofertas-novedades-button-active {
  color: #007cba;
  border: 1px solid #007cba;
}
.custom-dropdown-show {
  margin-left: 3px;
  font-size: 17px;
  color: #333;
  border: 1px solid #999;
  padding: 6px 6px;
  border-radius: 3px;
  cursor: pointer;
}
.custom-dropdown-show-active {
  margin-left: 3px;
  font-size: 17px;
  color: #007cba;
  border: 1px solid #007cba;
  padding: 6px 6px;
  border-radius: 3px;
  cursor: pointer;
}
.custom-dropdown-show:hover {
  color: #fff;
  background-color: #007cba;
}

.custom-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 85px;
  font-size: 16px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 10px;
  z-index: 1;
}

.custom-dropdown-content-active {
  display: block !important;
  position: absolute;
  right: 0rem;
  top: 40px;
}
.custom-dropdown-content-active p {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.shop-dropdown {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 15px;
  padding-right: 0px;
  select {
    font-size: 14px;
    font-weight: 500;

    color: $grey--twentyFour;
    border: none;
    background-color: transparent;
  }
}
.equivalencias {
  &__wrapper {
    padding: 0rem 1rem;
    padding-bottom: 2rem;
  }
  &__title {
    font-size: 1.2rem;
    margin-left: -0.5rem;
  }
}
